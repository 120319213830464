<template>
  <div
    class="px-0 container-fluid h-100"
    style="text-align: left"
  >
    <div
      class="container"
      style="padding-top: 30px"
    >
      <BreadCrumbComponent :pageTitles="['Gallery Category Sections', `${category.name?category.name:'...'}`]" />
    </div>
    <article>
      <section style="padding-top: 30px">
        <div class="container">
          <div class="row w-100">
            <div class="text-left col-12">
              <div style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;">
                <strong>{{ category.name?category.name:'...' }}</strong><br />
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>
    </article>

    <section v-loading="deleting">
      <!--- SHOW THIS ARTICLE WHEN FETCHING TEAMS FROM THE DATABASE HAS FAILED --->
      <article
        v-if="isLoadingError"
        class="d-flex align-items-center justify-content-center"
        style="height: 180px"
      >
        <div class="text-center">
          <div style="font-weight: 600; margin-top: 50px">Fetching Failed</div>
          <div
            class="pt-2"
            style="font-weight: 400; font-size: 0.9em"
          >
            Failed to fetch Teams now. Please try again
          </div>
          <button
            type="button"
            class="mx-0 mt-3 btn z-depth-0"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            @click="getTeams"
            style="
          width: 220px;
          text-transform: capitalize;
          height: 45px;
          border-radius: 4px;
          background-color: red;
          font-weight: 500;
        "
          >
            <span style="position: relative; bottom: 1px"><i class="pr-2 fas fa-refresh"></i> Try Again</span>
          </button>
        </div>
      </article>

      <!--- SHOW THIS ARTICLE WHEN STILL FETCHING TEAMS FROM THE SERVER --->
      <article
        v-show="isFetching && !isLoadingError"
        class=""
      >
        <div class="container">
          <div class="our_teams_grid">
            <div
              v-for="i in [1, 2, 3, 4, 5, 6, 7, 8]"
              :key="i"
              class="skeleton-block skeleton-effect-wave"
              style="height: 300px; border-radius: 5px"
            ></div>
          </div>
        </div>
      </article>

      <!--- SHOW THIS ARTICLE WHEN TEAMS ARE FETCHED FROM THE SERVER --->
      <article v-if="!isFetching && !isLoadingError">
        <div class="container">
          <div class="our_teams_grid">
            <div
              class="our_team_container"
              v-for="(item, index) in gallery_items"
              :key="index"
            >
              <div class="our_team_container_content">
                <div>
                  <div class="publish_content">
                    <img
                      :src="item.image_path_url"
                      style="width: 100%; height: 100%; object-fit: cover;"
                    >
                  </div>
                  <div class="publishing_status">
                    <el-button
                      :type="item.is_published == true ?'success' : 'danger'"
                      @click="changePublishStatus(item.is_published, item.website_gallery_item_id)"
                      size="mini"
                    >
                      {{ item.is_published == true ?'Published' : 'Not Published' }}
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="our_team_buttons">
                <el-tooltip content="Move up"
                  placement="top">
                  <el-button type="info"
                    icon="el-icon-top"
                    circle
                    size="mini"
                    :disabled="item.orderIndex == 0"
                    @click="moveGalleryItemUp(item.website_gallery_item_id, item.orderIndex)"></el-button>
                </el-tooltip>
                <el-tooltip content="Move Down"
                  placement="top">
                  <el-button type="info"
                  icon="el-icon-bottom"
                  size="mini"
                  circle
                  :disabled="item.orderIndex == (gallery_items.length - 1)"
                  @click="moveGalleryItemDown(item.website_gallery_item_id, item.orderIndex)"></el-button>
                </el-tooltip>
                <el-button
                  type="warning"
                  icon="el-icon-edit"
                  circle
                  size="small"
                  @click="editTeamMember(item.website_gallery_item_id)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="small"
                  @click="deleteGalleryCategoryItem(item.website_gallery_item_id)"
                ></el-button>
              </div>
            </div>
            <AddGalleryCategoryItemDialog
              :galleryCategoryId="$route.params.galleryCategorySectionId"
              @on-refresh="getGalleryCategoryItems"
            />
          </div>
        </div>
      </article>
    </section>

    <EditGalleryCategoryItemDialog
      :isEditGalleryCategoryItemDialogVisible="isEditGalleryCategoryItemDialogVisible"
      :galleryCategoryItemId="website_gallery_item_id"
      @on-refresh="getGalleryCategoryItems"
      @on-close-dialog="closeEditTeamMemberDialog"
    />
  </div>
</template>

<script>
import BreadCrumbComponent from "../components/bread-crumb-component";
import AddGalleryCategoryItemDialog from "../components/dialogs/add-gallery-category-item-dialog.vue";
import EditGalleryCategoryItemDialog from "../components/dialogs/edit-gallery-category-item-dialog.vue";
export default {
  components: {
    AddGalleryCategoryItemDialog,
    EditGalleryCategoryItemDialog,
    BreadCrumbComponent,
  },

  data() {
    return {
      isFetching: true,
      isLoadingError: false,
      isEditGalleryCategoryItemDialogVisible: false,
      website_gallery_item_id: "",

      loading: false,
      submiting: false,
      loadingError: false,
      deleting: false,
      gallery_items: [],
      category: {},
    };
  },

  mounted() {
    this.getGalleryCategoryItems();
  },

  methods: {
    editTeamMember(website_gallery_item_id) {
      this.isEditGalleryCategoryItemDialogVisible = true;
      this.website_gallery_item_id = website_gallery_item_id;
    },

    closeEditTeamMemberDialog() {
      this.isEditGalleryCategoryItemDialogVisible = false;
    },

    async getGalleryCategoryItems() {
      try {
        this.isFetching = true;
        this.isLoadingError = false;
        let request = await this.$http.get(
          `website/gallery/category-sections/${this.$route.params.galleryCategorySectionId}/items`
        );
        if (
          request.data.success &&
          request.data.message == "GALLERY ITEMS FETCHED SUCCESSFULLY"
        ) {
          this.gallery_items = request.data.gallery_items.map((item, index) => {
            return {
              website_gallery_item_id: item.website_gallery_item_id,
              image_path_url: item.image_path_url,
              is_published: item.is_published,
              video_link: item.video_link,
              orderIndex: index,
            }
          });
          this.category = request.data.category;
          this.isFetching = false;
          this.isLoadingError = false;
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.isFetching = false;
          this.isLoadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "CATEGORY NOT FOUND") {
            this.isFetching = false;
            this.isLoadingError = true;
            this.$router.push({ path: "/dashboard/gallery-categories" });
            return this.showWarningMessage(
              "Not Found",
              "Unable to find this Category"
            );
          }
        } else {
          this.isFetching = false;
          this.isLoadingError = true;
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        this.showFailedMessage("Operation Failed", "Unable to Fetch Category");
      }
    },

    async deleteGalleryCategoryItem(website_gallery_item_id) {
      await this.$confirm(
        "This will permanently delete this Gallery Category Item. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.deleting = true;
        let request = await this.$http.delete(
          `website/gallery/items/${website_gallery_item_id}`
        );
        if (
          request.data.success &&
          request.data.message == "GALLERY ITEM DELETED SUCCESSFULLY"
        ) {
          this.getGalleryCategoryItems();
          this.deleting = false;
          return this.showSuccessMessage(
            "Deleted Successfully",
            "The Gallery Category Item was successfully deleted"
          );
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "MEMBER DOES NOT EXIST") {
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the specified Gallery Category Item"
            );
          }
        } else {
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        this.showFailedMessage(
          "Operation Failed",
          "Unable to delete Gallery Category Item"
        );
      } finally {
        this.deleting = false;
      }
    },

    async changePublishStatus(is_published, website_gallery_item_id) {
      await this.$confirm(
        `Are you sure you want to change the publish status?`,
        "Confirm Changes",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.isBusy = true;
        let request = await this.$http.patch(
          `website/gallery/items/${website_gallery_item_id}/toggle-publish`
        );
        if (
          request.data.success &&
          request.data.message ==
            "GALLERY ITEM PUBLISHED STATUS TOGGLED SUCCESSFULLY"
        ) {
          this.getGalleryCategoryItems();
          this.showSuccessMessage(
            "Changes Saved Successfully",
            "The Publish Status was successfully changed"
          );
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "GALLERY ITEM NOT FOUND") {
            this.getGalleryCategoryItems();
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the provided gallery photo."
            );
          }
        } else {
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        this.showFailedMessage(
          "Operation Failed",
          "Unable To Change Category Publish Status"
        );
      } finally {
        this.isBusy = false;
      }
    },

        
    moveGalleryItemUp(galleryItemId, galleryItemOrderIndex) {
      // get previous item as we want to swap with it
      const affectedGalleryItem = this.gallery_items.filter(item => item.website_gallery_item_id == galleryItemId)[0];
      const currentIndex = affectedGalleryItem.orderIndex;

      const prevGalleryItem = this.gallery_items.filter(item => item.orderIndex == (galleryItemOrderIndex - 1))[0]

      // swap with previous item
      affectedGalleryItem.orderIndex = prevGalleryItem.orderIndex
      prevGalleryItem.orderIndex = currentIndex

      this.saveGalleryCategorySectionItemsOrder()
    },

    moveGalleryItemDown(galleryItemId, galleryItemOrderIndex) {
      // get previous item as we want to swap with it
      const affectedGalleryItem = this.gallery_items.filter(item => item.website_gallery_item_id == galleryItemId)[0];
      const currentIndex = affectedGalleryItem.orderIndex;

      const prevGalleryItem = this.gallery_items.filter(item => item.orderIndex == (galleryItemOrderIndex + 1))[0]

      // swap with previous item
      affectedGalleryItem.orderIndex = prevGalleryItem.orderIndex
      prevGalleryItem.orderIndex = currentIndex

      this.saveGalleryCategorySectionItemsOrder()
    },

    async saveGalleryCategorySectionItemsOrder() {
      let request = await this.httpRequest({
        method: "PATCH",
        url: "website/save-order-indexes",
        loadingPropertyName: "isFetching",
        errorLoadingPropertyName: "isLoadingError",
        showSuccessMessage: true,
        body: {
          itemType: "WEBSITE_GALLERY_ITEMS",
          items: this.gallery_items.map(item => {
            return {
              id: item.website_gallery_item_id,
              index: item.orderIndex
            }
          })
        },
      });

      if (
        request &&
        request.success &&
        request.message == "Items order saved successfully"
      ) {
        this.getGalleryCategoryItems();
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.our_teams_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.our_teams_grid > div {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.our_teams_grid > .our_team_container {
  position: relative;
}

.our_teams_grid > .our_team_container .our_team_buttons {
  display: none;
}

.our_teams_grid > .our_team_container:hover .our_team_buttons {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
}

.our_team_buttons .el-icon-edit {
  font-size: 1.5em;
  margin-right: 10px;
  color: orange;
  cursor: pointer;
}
.our_team_buttons .el-icon-delete {
  font-size: 1.5em;
  color: red;
  cursor: pointer;
}

.our_teams_grid > .our_team_container .our_team_container_content {
  border-radius: 5px;
}

.our_teams_grid > .our_team_container .our_team_container_content > div {
  width: 100%;
}

.our_teams_grid
  > .our_team_container
  .our_team_container_content
  > div:first-child {
  border-radius: 5px 5px 0px 0px;
  height: 200px;
  background-color: #2a70b5a0;
  color: white;
  position: relative;
}

.our_teams_grid
  > .our_team_container
  .our_team_container_content
  .publish_content {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  font-size: 3em;
}

.our_teams_grid
  > .our_team_container
  .our_team_container_content
  .publish_content
  img {
  border-radius: 5px;
}

.our_teams_grid
  > .our_team_container
  .our_team_container_content
  .publishing_status {
  position: absolute;
  bottom: 0px;
  right: 0px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .our_teams_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .our_teams_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}
</style>

<style>
.publishing_status .el-button {
  border-radius: 5px 0px 0px 5px;
}
</style>