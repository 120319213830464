<template>
  <div>
    <section>
      <div class="container">
        <div class="team_dialogs">
          <el-dialog
            title="Edit Item"
            :visible.sync="editGalleryCategoryItemModal"
            :destroy-on-close="true"
            :before-close="handleClose"
          >
            <div v-loading="submitting || isUploadingFile || isLoading">
              <el-form
                :model="galleryCategoryItemForm"
                label-position="top"
                ref="galleryCategoryItemForm"
                class="demo-galleryCategoryItemForm"
                onSubmit="return false;"
              >

                <div class="row">
                  <div class="col-md-12 text-left">
                    <div
                      class="mb-3"
                      style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
                    >
                      Choose File
                    </div>
                    <el-upload
                      class="upload-demo"
                      drag
                      :action="''"
                      :file-list="fileLists"
                      :on-remove="handleRemove"
                      :on-change="handlePreview"
                      :auto-upload="false"
                      :multiple="false"
                    >
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        Drop file here or <em>click to upload</em>
                      </div>
                    </el-upload>
                  </div>
                  <div class="col-md-12 mt-2">
                    <label 
                      for="video-link" 
                      style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
                    >
                    Youtube Video link(Optional)</label>
                    <el-input
                      id="video-link"
                      v-model="videoLink"
                      placeholder="Video Link"
                    ></el-input>
                  </div>
                </div>

                <el-form-item class="text-center mt-5">
                  <el-button
                    type="primary"
                    @click="editGalleryCategoryItem('galleryCategoryItemForm')"
                  >
                    Edit Item
                  </el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small></small>
                </div>
              </div>

            </div>
          </el-dialog>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  props: {
    galleryCategoryItemId: {
      type: String,
      required: true,
    },
    isEditGalleryCategoryItemDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editGalleryCategoryItemModal: false,
      submitting: false,
      isUploadingFile: false,
      isLoading: false,
      gallery_category_item: {},
      videoLink: "",

      selectedFile: null,
      fileLists: [],
      galleryCategoryItemForm: {},
    };
  },

  watch: {
    isEditGalleryCategoryItemDialogVisible() {
      if (this.isEditGalleryCategoryItemDialogVisible == true) {
        this.getOneGalleryCategoryItem(this.galleryCategoryItemId);
        this.editGalleryCategoryItemModal = true;
      } else {
        this.editGalleryCategoryItemModal = false;
      }
    },
  },

  methods: {
    handleClose(done) {
      done();
      this.$emit("on-close-dialog");
    },

    handlePreview(file) {
      this.fileLists = [file];
      this.selectedFile = file;
    },

    handleRemove() {
      this.selectedFile = null;
    },

    openTeamMemberModal() {
      this.editGalleryCategoryItemModal = true;
    },
    async getOneGalleryCategoryItem(website_team_member_id) {
      try {
        this.isLoading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `website/gallery/items/${website_team_member_id}`
        );
        if (
          request.data.success &&
          request.data.message == "GALLERY ITEM FETCHED SUCCESSFULLY"
        ) {
          this.gallery_category_item = request.data.gallery_item;
          this.selectedFile = this.gallery_category_item.image_path_url;
          this.videoLink = request.data.gallery_item.video_link;
          this.fileLists = [
            {
              name: "Gallery Category Item Photo",
              url: `${this.gallery_category_item.image_path_url}`,
            },
          ];
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "GALLERY ITEM NOT FOUND") {
            this.loadingError = true;
            this.$emit("on-refresh");
            this.$emit("on-close-dialog");
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the selected Photo"
            );
          }
        } else {
          this.loadingError = true;
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        return this.showFailedMessage(
          "Operation Failed",
          "Unable to Fetch Gallery Item"
        );
      } finally {
        this.isLoading = false;
      }
    },

    async editGalleryCategoryItem(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isUploadingFile = true;
          if (this.selectedFile == null) {
            this.isUploadingFile = false;
            return this.showWarningMessage("Empty", "Gallery Photo is Empty");
          }
          if (this.selectedFile.raw == undefined) {
            this.onAWSGalleryItemUploaded(this.selectedFile);
          } else {
            this.requestForFileUploadUrl();
          }
        } else {
          return false;
        }
      });
    },

    async requestForFileUploadUrl() {
      try {
        this.isUploadingFile = true;
        let request = await this.$http.post(
          `website/request-for-file-upload-url`,
          {
            file_name: this.selectedFile.raw.name,
            file_size: this.selectedFile.raw.size,
            reason: "GALLERY",
          }
        );
        if (
          request.data.success &&
          request.data.message == "UPLOAD URL GENERATED SUCCESSFULLY"
        ) {
          this.aws_upload_url = request.data.upload_url;
          this.uploadGalleryItemToAWS(
            this.aws_upload_url,
            this.selectedFile.raw
          );
        } else if (request.data.message == "UNSUPPORTED_FILE_TYPE") {
          return this.showWarningMessage(
            "Unsupported file",
            "The file you are trying to upload is not supported"
          );
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        if (error.message == "NetworkError") {
          this.isUploadingFile = false;
          return this.showWarningMessage(
            "Connection failed",
            "A network error occurred please try again"
          );
        }
        this.isUploadingFile = false;
        return this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Gallery Item Photo now, Please try again"
        );
      }
    },
    // ___________________________________________________ uploading files
    async uploadGalleryItemToAWS(aws_upload_url, selected_file) {
      this.isUploadingFile = true;
      try {
        const formData = new FormData();
        Object.entries(aws_upload_url.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", selected_file);
        this.isUploadingFile = true;
        console.log(selected_file);
        let request = await this.$http.post(`${aws_upload_url.url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: " ",
          },
          onUploadProgress: (progressEvent) => console.log(progressEvent),
        });

        if (request.status == 204) {
          console.log(request.headers.location);
          this.onAWSGalleryItemUploaded(request.headers.location);
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        console.log(error);
        if (error.message == "Network Error") {
          this.isUploadingFile = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.isUploadingFile = false;
        return this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Gallery Item Photo now, Please try again"
        );
      }
    },

    async onAWSGalleryItemUploaded(profile_photo_path_url) {
      this.isUploadingFile = true;
      try {
        this.isUploadingFile = true;
        let request = await this.$http.patch(
          `website/gallery/items/${this.galleryCategoryItemId}`,
          {
            image_path_url: decodeURIComponent(profile_photo_path_url),
            video_link: this.videoLink
          }
        );
        if (
          request.data.success &&
          request.data.message == "GALLERY ITEM UPDATED SUCCESSFULLY"
        ) {
          this.$emit("on-refresh");
          this.$emit("on-close-dialog");
          return this.showSuccessMessage(
            "Updated Successfully",
            "The Gallery Photo was successfully updated."
          );
        } else {
          throw "error occurred";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        return this.showFailedMessage(
          "Unable to Update Gallery Item",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.isUploadingFile = false;
      }
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.addDialogTrigger {
  border: 1px dashed black;
  border-radius: 5px;
  height: 100%;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.team_dialogs .el-dialog {
  width: 40%;
}
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .team_dialogs .el-dialog {
    width: 50%;
  }
}
/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .team_dialogs .el-dialog {
    width: 70%;
  }
}
/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .team_dialogs .el-dialog {
    width: 90%;
  }
}
/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .team_dialogs .el-dialog {
    width: 90%;
  }
}
</style>